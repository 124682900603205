import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import CategorisedFAQs from "./categorisedFAQs";

const FAQsPage = (props) => {

  const data = useStaticQuery(graphql`
    query FAQsPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "frequently-asked-questions"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
          faqImage
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground, faqImage} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/frequently-asked-questions" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground} capitalize={false}/>

      <div className="p-20">
        <ImageCaptionSection
          title="FAQs" imageAlt="FAQs" imageSrc={faqImage} mode="singleCaption"
          floating={false} fade={false} capitalize={false}/>
      </div>

      <CategorisedFAQs/>

      <HelpMethodsSection/>
    </Layout>
  );
};

FAQsPage.propTypes = {};

export default FAQsPage;
