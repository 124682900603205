import React from "react";

import EmphasisTextUnderlined from "./emphasisTextUnderline";
import FloatingSection from "./floatingSection";

const Div = ({children, ...props}) => <div {...props}>{children}</div>

function ImageCaptionSection(
  {title, subTitle, imageSrc, imageAlt, mode = "default",
    floating = true, fade = true, capitalize = true,
    fullHeight = false, useTextFadeIn=false, textColor="text-white"}) {
  const [textFadeIn, setTextFadeIn] = React.useState(!useTextFadeIn);
  React.useEffect(() => {
    if (!useTextFadeIn) return;
    const timeoutId = setTimeout(() => {
      setTextFadeIn(true);
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [useTextFadeIn]);

  const Container = floating ? FloatingSection : Div;
  return (
    <div
      className={`relative ${fullHeight ? "h-[100vh]" : "h-[70vh]"}`}
      style={{
        backgroundImage: `url(${imageSrc})`,
        backgroundAttachment: mode === 'default' ? 'fixed' : 'inherit',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "cover",
      }}
    >
      {mode === "default" && (
        <div className="absolute top-1/3 pl-20 pr-20 w-full text-white">
          <Container>
            <div className={`transition-opacity duration-1000 ${textFadeIn ? "opacity-100" : "opacity-0"}`}>
              <p className={`text-xl ${textColor}`}>{capitalize ? title?.toUpperCase() : title}</p>
              <EmphasisTextUnderlined text={subTitle} className={`text-8xl font-emphasis text-center ${textColor}`}
                                      strokeType="strokeSmallYellow"/>
            </div>
          </Container>
        </div>
      )}
      {mode === "singleCaption" && (
        <div className="absolute top-1/3 pl-5 pr-5 w-full text-white">
          <Container>
            <p className={`text-5xl text-center font-bold ${textColor}`}>{capitalize ? title?.toUpperCase() : title}</p>
          </Container>
        </div>
      )}

    </div>
  );
}

export default ImageCaptionSection;
