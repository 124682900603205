import React from "react";

import imgVolunteer from '../img/components/helpMethodsSection/volunteer.svg';
import imgDonate from '../img/components/helpMethodsSection/fundraise.svg';
import FloatingSection from "./floatingSection";
import EmphasisTextUnderlined from "./emphasisTextUnderline";
import {graphql, useStaticQuery} from "gatsby";

function HelpMethodsSection() {

  const data = useStaticQuery(graphql`
    query HelpMethodsSectionQuery {
      markdownRemark(frontmatter: {dataId: {eq: "common"}}) {
        frontmatter {
          helpMethodsSection {
            description
            title
          }
        }
      }
    }
  `);
  const helpMethods = data.markdownRemark.frontmatter.helpMethodsSection;
  const images = [imgVolunteer, imgDonate];
  const links = ["/get-involved#volunteer", "/donate"];

  return (
    <div className="pb-20 bg-[url('../img/backgrounds/background4.jpg')] bg-cover">
      <FloatingSection>
        <div className="flex justify-center">
          <EmphasisTextUnderlined text="How can you help?" strokeType="strokeLongYellow"
                                  className="font-emphasis text-8xl"/>
        </div>

        <div className="md:flex flex-row md:w-5/6 mx-auto mt-5">
          {helpMethods.map(({title, description}, index) => (
            <div key={index} className="flex-1 p-10">
              <div className="bg-white p-10 pb-20">
                <img src={images[index]} className="mx-auto h-96 w-96"/>
                <h3 className="text-xl font-bold text-center">{title}</h3>
                <p className="text text-center mt-5 text-justify">{description}</p>
                <a href={links[index]} className="block mx-auto mt-10 px-8 py-4 bg-yellow-500 hover:bg-yellow-700
                rounded-full text-white font-bold text-center w-fit">
                  READ MORE
                </a>
              </div>
            </div>
          ))}
        </div>
      </FloatingSection>
    </div>
  );
}

export default HelpMethodsSection;
