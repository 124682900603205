import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function CategorisedFAQsSection() {
  const [curSectionIndex, setCurSectionIndex] = React.useState(0);

  const data = useStaticQuery(graphql`
    query CategorisedFAQsSectionQuery {
      faqs: markdownRemark(
        frontmatter: {templateKey: {eq: "frequently-asked-questions"}}
      ) {
        frontmatter {
          generalFAQs {
            question
            answer
          }
          specialEducationFAQs {
            question
            answer
          }
          tbControlFAQs {
            question
            answer
          }
          lifeLongCareFAQs {
            question
            answer
          }
          coursesFAQs {
            question
            answer
          }
          volunteersFAQs {
            question
            answer
          }
        }
      }
      common: markdownRemark(frontmatter: {dataId: {eq: "common"}}) {
        frontmatter {
          contactDetails {
            phone
            phoneDisplay
            email
          }
        }
      }
    }
  `);
  const {
    generalFAQs,
    specialEducationFAQs,
    tbControlFAQs,
    lifeLongCareFAQs,
    coursesFAQs,
    volunteersFAQs,
  } = data.faqs.frontmatter;

  const {email, phone, phoneDisplay} = data.common.frontmatter.contactDetails;

  const faqCategories = [
    ["GENERAL", generalFAQs],
    ["SPECIAL EDUCATION", specialEducationFAQs],
    ["TB TREATMENT", tbControlFAQs],
    ["LIFELONG CARE", lifeLongCareFAQs],
    ["COURSES", coursesFAQs],
    ["VOLUNTEERS", volunteersFAQs],
  ];

  return (
    <div className="md:flex flex-row">
      <div className="md:w-1/3 px-5 md:pl-24">
        <p className="hidden md:block text-5xl mb-10">Help Center</p>

        <div className="flex flex-row md:flex-col items-start flex-nowrap overflow-x-auto md:overflow-none
        space-x-8 md:space-x-0">
        {faqCategories.map(([title], index) => (
          <button
            onClick={() => setCurSectionIndex(index)}
            className={`min-w-max md:min-w-0 flex-1 text-left text-xl md:text-2xl mb-5 ${
              curSectionIndex === index ? "text-yellow-500" : ""}`}>
            {title}
          </button>
        ))}
          </div>
      </div>
      <div className="md:w-2/3 p-10 md:pl-20 md:pr-48">
        <p className="text-xl pb-5 text-justify">
          If you have any questions or can’t find an answer you’re looking for, please call us on{" "}
          <a className="text-red-500" href={`tel:${phone}`}>{phoneDisplay}</a>{" "}
          or email us at{" "}
          <a className="text-red-500" href={`mailto:${email}`}>{email}</a>.{" "}
          We want to help you and answer all your questions.
        </p>
        <p className="text-xl pb-20">
          Here are the most frequently asked questions:
        </p>

        <div>
          {faqCategories[curSectionIndex]?.[1]?.map(({question, answer}, index) => (
            <div key={`${curSectionIndex}_${index}`} className="pb-20">
              <p className="text-xl font-bold text-justify italic pb-10">Q: {question}</p>
              <p className="text-xl text-justify">A: {answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CategorisedFAQsSection;
